import React from "react";
import Card from "./Card";
import './DeckOfCards.css'

export default ({cards, hideFace, reverse, onClick}) => {
  if (reverse) {
    cards.reverse();
  }

  if (cards.length > 0) {
    const card = cards[0];
    if (hideFace) {
      return <div onClick={onClick} className="DeckOfCards-Cards-Container">
          <Card suit={card.suit} rank={card.rank}  hideFace={true}  />
        </div>
    }
    else {
      return <div className="DeckOfCards-Cards-Container">
        <Card suit={card.suit} rank={card.rank} hideFace={false} newSuit={card.newSuit}/>
      </div>
    }
  }
  return <div>Deck of cards</div>
}