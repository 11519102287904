import React, {useEffect, useRef, useState} from "react";
import socketIOClient from "socket.io-client";
import useEventListener from "./hooks/useEventListener";

export const SocketContext = React.createContext();

export const SocketProvider = ({children}) => {
  const [socket, setSocket] = useState(null);
  const [playerId, setPlayerId] = useState(null);
  const [connected, setConnected] = useState(false)
  const thePlayerId = useRef();

  useEffect(() => {
    const socket = socketIOClient('/')

    socket.on('connect', () => {
      console.log('Connected to ws');
    })

    socket.on('reconnect', () => {
      alert('sending socket reconnect' + thePlayerId.current);
      socket.send({
        action: 'SET_PLAYER_ID',
        data: {
          id: thePlayerId.current
        }
      })
    })

    socket.on("message", async (message) => {
      // We just connected:
      message = JSON.parse(message);
      console.log('Got message ', message);

      switch (message.action) {
        case "CONNECTED":
          if (!thePlayerId.current) {
            setPlayerId(message.data.player.id);
            thePlayerId.current = message.data.player.id;
          }
          setConnected(true);
          break;
      }
    })
    setSocket(socket);
  }, [])

  return (
    <SocketContext.Provider
      value={{
        socket, playerId, connected
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

export const useSocket = () => React.useContext(SocketContext);