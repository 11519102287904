import React, {useEffect, useState} from 'react';
import './EnterGamePopup.css';
import {useMediaService} from "../MediaProvider";
import Video from "./Video";

const getInitialName = () => {
  const name = localStorage.getItem('name');
  if (name) {
    return name;
  }
  return "";
}

export default ({nextFunction, onSetName}) => {
  const {hasAccess, askForCamera, lastError} = useMediaService();
  useEffect(() => {
      // Ask for access if we have it:
    if (hasAccess) {
      askForCamera();
    }
  }, [hasAccess])

  console.log('Last error', lastError)

  return <div className="Popup-Background">
      <div className="Popup">
        {!hasAccess && <NoAccessInfo askForCamera={askForCamera} lastError={lastError} />}
        {hasAccess && <HasAccess nextFunction={nextFunction} onSetName={onSetName}/>}
      </div>
  </div>
}

const NoAccessInfo = ({askForCamera, lastError}) => {
  return <div>
    {lastError && <div>{lastError}</div>}
    <button className="Button" onClick={askForCamera}>Gi tilgang til kamera</button>
  </div>
}

const HasAccess = ({nextFunction, onSetName}) => {
  const {stream, askForAccess} = useMediaService();
  const [name, setName] = useState(getInitialName());

  const onNameChange = (e) => {
    setName(e.target.value)
  }

  const onSubmit = (e) => {
    onSetName(e.target.elements[0].value)
    nextFunction();
    e.preventDefault();
  }

  return <div>
    <Video stream={stream} isMe={true} />
    <form onSubmit={onSubmit}>
      <input type="text" name="name" placeholder="Ditt navn" value={name} onChange={onNameChange} />
      <button>Bli med!</button>
    </form>
  </div>
}