import React from 'react';
import './ChooseSuit.css'
import Club from '../images/Club.svg';
import Diamond from '../images/Diamond.svg';
import Spade from '../images/Spade.svg';
import Heart from '../images/Heart.svg';

export default ({chooseSuit, onClose}) => {
  const doClose = (e) => {
    e.stopPropagation();
    onClose();
  }
  return (<div className="ChooseSuit-Container" onClick={doClose}>
    <h2>Velg ny farge</h2>
    <ul className="ChooseSuit-Cards">
      <li onClick={(e) => chooseSuit(e, 'C')}><img src={Club} /></li>
      <li onClick={(e) => chooseSuit(e, 'S')}><img src={Spade} /></li>
      <li onClick={(e) => chooseSuit(e, 'H')}><img src={Heart} /></li>
      <li onClick={(e) => chooseSuit(e, 'D')}><img src={Diamond} /></li>
    </ul>
  </div>)
}