import React, {useEffect, useState} from 'react';
import './MyCards.css';
import Card from "./Card";
import useSortedCards from "../hooks/useSortedCards";
import {CSSTransition, TransitionGroup} from "react-transition-group";


function MyCards({cards, playCard, hideFace}) {
  const sortedCards = useSortedCards(cards);
  console.log('Hide face', hideFace)
  return (
    <div className="MyCards">
      <TransitionGroup className="MyCards-TransitionGroup">
        {sortedCards.map((card, i) => {
          return (
            <CSSTransition
              key={card.id}
              timeout={hideFace ? 0 : 500}
              classNames={hideFace ? "MyCards-YourCard-Transition" : "MyCards-Card-Transition"}
            >
              <div className={hideFace ? 'MyCards-YourCard' : 'MyCards-Card'} onClick={() => playCard(card)}>
                  <Card rank={card.rank} suit={card.suit} hideFace={hideFace} />
              </div>
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </div>
  );
}

export default MyCards;
