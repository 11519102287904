import Vri8er from "./Vri8er";

import React, {useEffect, useState} from "react";
import useGameService from "../hooks/useGameService";
import { useRoomService } from "../RoomServiceProvider";
import {useSocket} from "../SocketProvider";
import Quiz from "./Quiz/Quiz";
import Amerikaner from "./Amerikaner/Amerikaner";

export default () => {
  const {socket} = useSocket();
  const {roomMembers} = useRoomService();
  const {game, dispatch} = useGameService();

  useEffect(() => {
    // Say we're ready:
    dispatch({action: 'PLAYER_READY'});
  }, [])

  if (game) {
    console.log('We got a game!', game)
    if (game.type == 'vri8er') {
      return <Vri8er dispatch={dispatch} game={game} />
    }
    else if (game.type == 'quiz') {
      return <Quiz dispatch={dispatch} game={game} />
    }
    else if (game.type == 'amerikaner') {
      return <Amerikaner dispatch={dispatch} game={game} />
    }
  }

  return <div>
    Loading
  </div>
}