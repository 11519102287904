import React, {useCallback, useEffect, useRef, useState} from "react";
import socketIOClient from "socket.io-client";
import useEventListener from "./hooks/useEventListener";
import {useSocket} from "./SocketProvider";
import useRefState from "./hooks/useRefState";
import {useMediaService} from "./MediaProvider";
import * as Sentry from '@sentry/browser';
var Peer = require('simple-peer')

export const RoomServiceContext = React.createContext();

export const RoomServiceProvider = ({children}) => {
  const {socket, playerId, connected} = useSocket();
  const [playerStatus, setPlayerStatus] = useState('no-room');
  const [roomStatus, setRoomStatus] = useState(null)
  const [roomMembers, setRoomMembers] = useState([])
  const [lastError, setLastError] = useState('');
  const [roomId, setRoomId] = useState(null);
  const [notUsedPeerConnections, setPeerConnections, peerConnections] = useRefState({});
  const [remoteStreams, setRemoteStreams, _remoteStreams] = useRefState({});
  const [videoTrack, setVideoTrack] = useState(null);
  const [audioTrack, setAudioTrack] = useState(null);
  const [oldStream, setOldStream] = useState(null);

  const {stream, isMuted} = useMediaService();
  const theRoomId = useRef();
  const theSocket = useRef();
  const myStream = useRef();


  // If we get disconnected:
  const onReconnect = useCallback( (e, f) => {
      alert('on reconnect'  + roomId);
      console.log('Reconnect event', e, f);
      if (theRoomId.current) {
        const data = {
          action: 'REJOIN_ROOM',
          data: {
            player: {
              name: localStorage.getItem('name'),
              id: playerId,
            },
            room: {
              id: theRoomId.current
            }
          }
        };
        theSocket.current.send(data)
      }
  }, []);

  useEffect(() => {
    // Socket, disconnect:
    theSocket.current = socket;
    if (socket) {
      socket.on('reconnect', onReconnect);
    }
    return () => {
      socket && socket.off('reconnect', onReconnect);
    }
  }, [socket])

  const joinRoom = useCallback((roomId) => {
    const data = {
      action: 'JOIN_ROOM',
      data: {
        player: {
          name: localStorage.getItem('name'),
          id: playerId,
        },
        room: {
          id: roomId
        }
      }
    };
    socket.send(data)
  })

  const onPlayerLeft = (data) => {
     const playerId = data.player.id;
     setRoomMembers(roomMembers => {
       return roomMembers.filter(rm => rm.id !== playerId)
     })
    setPeerConnections(pcs => {
      if (pcs[playerId]) {
        pcs[playerId].peer.destroy();
        delete pcs[playerId];
      }
      return pcs;
    });
    setRemoteStreams(rss => {
      if (rss[playerId]) {
        delete rss[playerId]
      }
      return rss;
    });
  }

  const onPlayerJoined = (data) => {
    setRoomMembers(data.players);

    if (data.players.length > 1) {
      console.log('On room joined');
      console.log('All players', data.players);
      if (data.player.id == playerId) {
        for (const p of data.players) {
          if (p.id != playerId) {
            startWebRtc(p.id, true)
          }
        }
      }
      else {
        startWebRtc(data.player.id, false)
      }
    }
  }

  const startWebRtc = async (pid, isOfferer) => {

    // We need to create a peer connection for each client:
    const config = {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302'
        },
        {
          urls: 'turn:turn.breim.no:3478',
          username: 'spelbreim',
          credential: 'spel'
        }
      ],
     // sdpSemantics: 'unified-plan'
    }

    const peer = new Peer({
      initiator: isOfferer,
      config
    })
    peer._debug = console.log

    const newPc = {};
    newPc[pid] = {
      polite: isOfferer,
      peer: peer
    };

    const newPeerConnections = Object.assign({}, peerConnections.current, newPc);
    setPeerConnections(newPeerConnections);

    const newRS = {};
    newRS[pid] = null;
    setRemoteStreams(Object.assign({}, _remoteStreams.current, newRS));


    peer.on('signal', data => {
      // We need to send this to other peer:
      if (data.candidate) {
        /*
        // If we want to test only TURN connections
        const components = data.candidate.candidate.split( / /);
        console.log('Ice components', components);
        if (components[7] == 'relay') {
          console.log('Sending ice candidate', data.candidate);
          sendPM(pid, {type: 'peer', message: data})
        }
        else {
          console.log("Ignore local host.");
        }
        */
        sendPM(pid, {type: 'peer', message: data})
      }
      else {
        sendPM(pid, {type: 'peer', message: data})
        console.log('Got signal ', data)
      }
    });

    peer.on('error', error => {
      console.log('Peer error', error)
      if (error.code == 'ERR_CONNECTION_FAILURE') {
        startWebRtc(pid, isOfferer);
      }

    })

    peer.on('connect', () => {
      console.log('Connected');

      // Send some bullshit:
      //peer.send('Data channel message');
    })

    peer.on('track', (track) => {
        console.log('Got new track', track)
    });

    peer.on('stream', stream => {
      console.log('STREAM - Got new stream event')
      // got remote video stream, now let's show it in a video tag
      const newRS = {};
      newRS[pid] = {
        stream,
        muted: false,
        volume: 0
      };
      const newData = Object.assign({}, _remoteStreams.current, newRS);
      setRemoteStreams(newData);
    });

    peer.on('data', data => {
      data = JSON.parse(data);

      if (data.type == 'volume') {
        const newData = {volume: data.volume};
        const newRS = {};
        newRS[pid] = Object.assign({}, _remoteStreams.current[pid], newData);
        const newStreams = Object.assign({}, _remoteStreams.current, newRS);
        setRemoteStreams(newStreams);
      }
      else if (data.type == 'muted') {
        const newData = {muted: data.muted};
        const newRS = {};
        newRS[pid] = Object.assign({}, _remoteStreams.current[pid], newData);
        const newStreams = Object.assign({}, _remoteStreams.current, newRS);
        setRemoteStreams(newStreams);
      }
    })

    peer.on('error', err => {
      Sentry.captureException(err);
    })

    if (myStream.current) {
      for (const track of myStream.current.getTracks()) {
        if (track.kind == 'video') {
          setVideoTrack(track);
        }
        else if (track.kind == 'audio') {
          setAudioTrack(track)
        }
        peer.addTrack(track, myStream.current)
        setOldStream(myStream.current);
      }
    }
  }

  const sendPM = (recipient, data) => {
    console.log('Sending pm to ', recipient, data);
    socket.send({
      action: 'PM',
      data: Object.assign({}, data, {recipient, sender: playerId})
    })
  }

  const sendRoomMessage = (rid, data) => {
    if (!rid) {
      rid = roomId;
    }
    console.log('Sending room message', rid)
    socket.send({
      action: 'ROOM_MESSAGE',
      data: Object.assign({}, data, {roomId: rid})
    })
  }

  const onRoomJoined = async (data) => {
    setRoomStatus(data.status);
    setRoomMembers(data.players);
    setRoomId(data.id);
    theRoomId.current = data.id;
  }

  const handleRoomMessage = (data) => {
    console.log('Got room message')
    if (data.sender == playerId) {
      console.log('Ignoring message from selv')
      return;
    }
  }

  const handlePeer = (data) => {
    const peer = peerConnections.current[data.sender].peer;
    console.log('Handle peer', peer);
    peer.signal(data.message);
  }

  const handlePm = (data) => {

    if (data.type == 'peer') {
      handlePeer(data);
    }
  }

  useEffect(() => {
    if (!socket) {
      return;
    }
    console.log('Room subscribe to events', socket, roomId)
    const handler = (message) => {
      message = JSON.parse(message);
      console.log('Room got message', message)
      switch (message.action) {
        case "ROOM_CREATED":
          joinRoom(message.data.id)
          break;
        case "ROOM_FULL":
          setLastError('Rommet er fullt')
          break;
        case "ROOM_TOO_LATE":
          setLastError('Spelet har allerede begynt')
          break;
        case "ROOM_NOT_FOUND":
          setLastError('Rommet blei ikkje funnet')
          break;
        case "ROOM_JOINED":
          onRoomJoined(message.data);
          break;
        case "ROOM_MESSAGE":
          handleRoomMessage(message.data);
          break;
        case "PLAYER_JOINED":
          onPlayerJoined(message.data)
          break;
        case "PLAYER_LEFT":
          onPlayerLeft(message.data)
          break;
        case "GAME_INIT":
          setRoomStatus('in-game')
          break;
        case "PM":
          handlePm(message.data);
          break;
        default: {
          console.log('We really should just handle our own messages', message);
        }
      }
    }
    socket.on('ROOM', handler);
    return () => {
      socket.off('ROOM', handler);
    }
    // Bind:
  }, [socket, playerId, roomId])

  const dispatch = (action, data) => {
    setLastError('');
    socket.send({
      action,
      data
    })
  }

  const newGame = (type) => {
    dispatch(
       'NEW_GAME',
      {type}
    );
  }

  const createRoom = () => {
    dispatch('CREATE_ROOM');
  }

  useEffect(() => {
    if (!stream) {
      return;
    }

    for (const peerConnection of Object.values(peerConnections.current)) {
      try {
        peerConnection.peer.send(JSON.stringify({type: 'muted', muted: isMuted}))
      }
      catch (err) {
        console.log('Error sending muted', err)
      }
    }

  }, [isMuted]);

  useEffect(() => {
    if (!stream) {
      return;
    }

    let newVideoTrack = stream.getVideoTracks()[0];
    let newAudioTrack = stream.getAudioTracks()[0];


    for (const peerConnection of Object.values(peerConnections.current)) {
      try {
        peerConnection.peer.replaceTrack(videoTrack, newVideoTrack, oldStream);
        peerConnection.peer.replaceTrack(audioTrack, newAudioTrack, oldStream);
      }
      catch (err) {
        Sentry.captureException(err);
      }
    }

    // Update reference:
    myStream.current = stream;
    //setOldStream(stream);

    setAudioTrack(newAudioTrack);
    setVideoTrack(newVideoTrack);

  }, [stream]);


  console.log('Room service render');
  return (
    <RoomServiceContext.Provider
      value={{
        dispatch,
        playerStatus,
        roomStatus,
        roomMembers,
        joinRoom,
        lastError,
        createRoom,
        roomId,
        remoteStreams,
        myStream: stream,
        newGame,
      }}
    >
      {children}
    </RoomServiceContext.Provider>
  )
}

export const useRoomService = () => React.useContext(RoomServiceContext);