import React, {useCallback, useEffect, useState} from "react";


export const MediaServiceContext = React.createContext();

const videoDefaults = {
  width: {ideal: 320},
  height: {ideal: 240},
  facingMode: 'user',
  frameRate: {
    // Ideal breaks firefox :/
    //ideal: 15,
    min: 1
  },
}

export const MediaServiceProvider = ({children}) => {
  const [stream, setStream] = useState(null);
  const [hasAccess, setHasAccess] = useState(false)
  const [videoDeviceId, setVideoDeviceId] = useState(null)
  const [audioInputDeviceId, setAudioInputDeviceId] = useState(null)
  const [isMuted, setIsMuted] = useState(false);
  const [lastError, setLastError] = useState(null);

  useEffect(() => {
    // Check if we have access:
    const isFirefox = (navigator.userAgent.toLowerCase().indexOf('firefox') > -1);

    !isFirefox && navigator.permissions && navigator.permissions.query({name:'camera'})
      .then( (result) => {
        console.log('Camera access', result)
        if (result.state == 'granted') {
          console.log('We have access to the camera!');
          setHasAccess(true);
        }
        else {
          setHasAccess(false);
        }
      })
      .catch (err => {
        console.log('Error checking access', err)
      })
}, [])

  const askForCamera = () => {
    // We only ask if we don't have a stream?
    if (!stream) {
      navigator.mediaDevices.getUserMedia({
        audio: true,
        video: videoDefaults,
      }).then(stream => {
        console.log('Got stream', stream);
        setStream(stream);
        setHasAccess(true);

        // Get devices for tracks for settings:
        const tracks = stream.getTracks();
        navigator.mediaDevices.enumerateDevices()
          .then(devices => {
            console.log('My devices', devices);
            for (const device of devices) {
              for (const track of tracks) {
                const deviceId = track.getSettings().deviceId;
                if (deviceId ==  device.deviceId) {
                  if (track.kind == 'audio') {
                    setAudioInputDeviceId(deviceId);
                  } else if (track.kind == "video") {
                    console.log('Got video track', track, track.getSettings());
                    setVideoDeviceId(deviceId);
                  }
                }
              }
            }
          });

      }, (err) => {
        console.log('Error getting user media', err)
        setLastError(err.message);
        setHasAccess(false);
      });
    }
  }


  const setVideoDevice = (deviceId) => {
    setVideoDeviceId(deviceId)
  }

  const setAudioInputDevice = (deviceId) => {
    setAudioInputDeviceId(deviceId)
  }

  const toggleMuted = () => {
    setIsMuted(!isMuted);
  }

  useEffect(() => {
    if (stream) {
      stream.getAudioTracks()[0].enabled = !isMuted;
    }
  }, [isMuted])

  useEffect(() => {
    if (!stream) {
      return;
    }

    let video = videoDefaults;
    if (videoDeviceId) {
      video = Object.assign(videoDefaults, {deviceId: videoDeviceId});
    }

    let audio = true;
    if (audioInputDeviceId) {
      audio = {
        deviceId: audioInputDeviceId
      }
    }

    console.log('Setting video and audio device', videoDeviceId, audioInputDeviceId, audio)
    navigator.mediaDevices.getUserMedia({
      audio: audio,
      video: video,
    }).then(stream => {
      setStream(stream);
    }, (err) => {
      console.log('Error getting user media on change stream', err)
    });
  }, [videoDeviceId, audioInputDeviceId]);


  return (
    <MediaServiceContext.Provider
      value={{
        stream,
        setVideoDevice,
        setAudioInputDevice,
        videoDeviceId,
        audioInputDeviceId,
        askForCamera,
        hasAccess,
        isMuted,
        toggleMuted,
        lastError
      }}
    >
      {children}
    </MediaServiceContext.Provider>
  )
}

export const useMediaService = () => React.useContext(MediaServiceContext);