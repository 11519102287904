import {useEffect, useRef, useState} from "react";

export default (initialValue) => {
  const [state, _setState] = useState(initialValue);
  const stateRef = useRef(state);

  const setState = (state) => {
    stateRef.current = state;
    _setState(state);
  }

  return [state, setState, stateRef];
};