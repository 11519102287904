import {useEffect, useRef, useState} from 'react';

export default (stream) => {
  const [volume, setVolume] = useState(0);

  let theVolume = useRef();
  let interval = useRef();

  useEffect(() => {

    return;

    console.log('Audio meter stream change')

    if (stream && window.AudioContext) {
      console.log('Audio meter has stream')
      const audioContext = new AudioContext();

      const mediaStreamSource = audioContext.createMediaStreamSource(stream)
      const scriptProcessor = audioContext.createScriptProcessor(512);
      mediaStreamSource.connect(scriptProcessor);

      scriptProcessor.connect(audioContext.destination);
      scriptProcessor.onaudioprocess = function(event) {
        const input = event.inputBuffer.getChannelData(0);
        //console.log(input);
        const sum = input.reduce((sum, c) => {
          return sum + c;
        }, 0)

        const volume = Math.sqrt(sum / input.length);
        theVolume.current = volume || 0;
      };

    }

    interval.current = setInterval(() => {
      setVolume(theVolume.current)
    }, 200);
    return () => {
      clearInterval(interval.current)
    }
  }, [stream])

  return [volume];
}