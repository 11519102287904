import {useCallback, useEffect, useState} from "react";
import socketIOClient from "socket.io-client";
import useEventListener from "./useEventListener";
import {useSocket} from "../SocketProvider";

export default () => {
  const {socket, playerId} = useSocket();
  const [game, setGame] = useState(null)
  const [waitingForPlayers, setWaitingForPlayers] = useState(true);

  useEffect(() => {
    if (!socket) {
      return;
    }

    // Connect to websocket:
    socket.on("GAME", async (message) => {
      message = JSON.parse(message);
      switch (message.action) {
        case 'GAME_UPDATE':
          console.log('Game data', message.data)
          setGame(message.data.game);
          break;
        default:
          console.log('Got unknown game message', message)
          break;

      }
    });
  }, [socket]);


  const dispatch = (action) => {
    console.log('Dispatch', action)
    socket.send(action)
  }

  return {game,  dispatch, waitingForPlayers}
}