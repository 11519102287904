import React, {useEffect, useRef, useState} from 'react';
import './StartPage.css';
import {useHistory} from "react-router";
import { useRoomService } from "../RoomServiceProvider";
import PreGame from "./PreGame";
import GamePage from "./GamePage";
import * as Sentry from '@sentry/browser';
import EnterGamePopup from "./EnterGamePopup";
import {useSocket} from "../SocketProvider";
import useBrowserCheck from "../hooks/useBrowserCheck";



export default () => {
  const input = useRef(null);
  const history = useHistory()
  const {playerId} = useSocket();
  const {roomStatus, lastError, createRoom, joinRoom} = useRoomService();
  const supportedBrowser = useBrowserCheck();

  const [nextAction, setNextAction] = useState('');

  const nextFunction = () => {
    if (nextAction == 'new-game') {
      onNewGame();
    }
    else {
      onEnterGame();
    }
  }

  const onNewGame = () => {
      createRoom();
  }

  const onEnterGame = () => {
    const id = input.current.value;
    joinRoom(id)
  }

  const onSetName = (name) => {
    localStorage.setItem('name', name);
    Sentry.configureScope(scope => {
      scope.setUser({ id: playerId, name: name });
    });
  }

  useEffect(() => {

  }, [])


  useEffect(() => {
    input && input.current && input.current.focus();
  }, [input])

  const submitForm = (e) => {
    setNextAction('join-game')
    e.preventDefault ();
  }

  if (supportedBrowser == 'checking') {
    return <div className="StartPage">
      <div className="StartPage-Container">
        Sjekker om nettleseren er støtta
      </div>
    </div>
  }
  else if (supportedBrowser == 'not-supported') {
    return <div className="StartPage">
      <div className="StartPage-Container">
        Dessverre kan du ikkje bruke denne nettleseren. Prøv gjerne nyeste Chrome!
      </div>
    </div>
  }

  if (roomStatus == 'lobby') {
    return <PreGame />
  }

  if (roomStatus == 'in-game') {
    return <GamePage />
  }



  return (
    <div className="StartPage">
      <div className="StartPage-Container">
        <form onSubmit={submitForm}>
          <input ref={input} className="StartPage-GameId" name="number" placeholder="Spelkode" />
          {lastError}
          <button className="StartPage-Join-Game" onClick={() => setNextAction('join-game')}>Spel!</button>
        </form>
        <div>
          eller
        </div>
        <button className="StartPage-Create-Game" onClick={() => {
          setNextAction('new-game')
        }}>Start nytt spel!</button>
      </div>
      {nextAction && !lastError && <EnterGamePopup nextFunction={nextFunction} onSetName={onSetName}/>}
    </div>
  );
}
