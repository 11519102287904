import React, {useEffect, useRef, useState} from "react";
import './Video.css';
import {useRoomService} from "../RoomServiceProvider";
import {useMediaService} from "../MediaProvider";
import useSoundMeter from "../hooks/useSoundMeter";

export default ({stream, isMe, remoteMuted}) => {
  const videoRef = useRef();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const {setVideoDevice, setAudioInputDevice, isMuted, toggleMuted, videoDeviceId, audioInputDeviceId} = useMediaService();
  const [volume] = useSoundMeter(stream);

  useEffect(() => {
    console.log('Video stream changed, starting', stream)
    if (stream) {
      videoRef.current.srcObject = stream;
    }

    if (stream && !isMe) {
      // Show bandwidth:
      setInterval(() => {
        console.log('Video info',
            stream.getVideoTracks()[0].getSettings(),
            stream.getAudioTracks()[0].getSettings())
      }, 5000);
    }



  }, [stream])


  const bgColor = `rgba(0, 0, 0, ${volume*100})`;
  console.log('BG color', bgColor, remoteMuted);

  return <div className="Video-Container" style={{backgroundColor: bgColor}}>
    <video ref={videoRef} muted={isMe} autoPlay={true} />
    {isMe && <button className="Video-Settings-Button" onClick={() => setSettingsOpen(true)}><span className="material-icons">settings</span></button>}
    {isMe && <button className="Video-Settings-Button Video-Settings-Button-Audio"
                     onClick={() => toggleMuted()}><span className="material-icons">
                  {isMuted ? 'mic_off' : 'mic'}
                  </span></button>}
    {!isMe && remoteMuted && <RemoteStreamMuted isMuted={remoteMuted}/>}
    {isMe && settingsOpen && <Settings
      isOpen={settingsOpen} stream={stream} setVideoDevice={setVideoDevice}
      setAudioInputDevice={setAudioInputDevice} onClose={() => setSettingsOpen(false)}
      videoDeviceId={videoDeviceId} audioInputDeviceId={audioInputDeviceId}
    />}

  </div>
}


const Settings = ({isOpen, stream, videoDeviceId, audioInputDeviceId, setVideoDevice, setAudioInputDevice, onClose}) => {
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);

  useEffect(() => {
    if (isOpen) {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          console.log('Devices', devices)
          const videos = devices.filter(mdi => mdi.kind == 'videoinput');
          setVideoDevices(videos);
          const audio = devices.filter(mdi => mdi.kind == 'audioinput');
          setAudioInputDevices(audio);
          const audioOut = devices.filter(mdi => mdi.kind == 'audiooutput');
          setAudioOutputDevices(audioOut);
          console.log('Devices', devices)
        })
    }
  }, [isOpen])

  if (!isOpen) {
    return null;
  }

  console.log('Stream is', stream, videoDeviceId)
  return <div className="Settings-Container">
    <select onChange={(e) => setVideoDevice(e.target.value)} value={videoDeviceId}>
      {videoDevices.map(videoDevice => {
        console.log('Video device', videoDevice);
        return <option key={videoDevice.deviceId} value={videoDevice.deviceId}>{videoDevice.label}</option>
      })}
    </select>
    <select onChange={(e) => setAudioInputDevice(e.target.value)} value={audioInputDeviceId}>
      {audioInputDevices.map(videoDevice => {
        console.log('Video device', videoDevice);
        return <option key={videoDevice.deviceId} value={videoDevice.deviceId}>{videoDevice.label}</option>
      })}
    </select>
    <button onClick={onClose}>OK</button>
  </div>

}

const RemoteStreamMuted = ({isMuted}) => {
  console.log('Remote stream muted', isMuted);
  return <div className="Video-Remote-Mute">
    <span className="material-icons">{isMuted ? 'mic_off' : ''}</span></div>

}