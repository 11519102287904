import React from 'react';
import './Card.css';
import cardBack from '../images/CardBack.svg'
import Club from '../images/Club.svg';
import Diamond from '../images/Diamond.svg';
import Spade from '../images/Spade.svg';
import Heart from '../images/Heart.svg';

const getSuit = (letter) => {
  switch (letter) {
    case "C":
      return Club;
    case "S":
      return Spade;
    case "D":
      return Diamond;
    case "H":
      return Heart;
  }
}

function Card({suit, rank, hideFace, newSuit}) {
  return (
    <div className="Card">
      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
      <div className="Card-Inner-Container">
        <div className="Card-Content-Container">
          {!hideFace && <div className="Card-Suit-Container">
            <span>{rank}</span>
            <img className="Card-Suit" src={getSuit(suit)} />
          </div>}
          {newSuit && <div  className="Card-NewSuit"><img src={getSuit(newSuit)} /></div>}
          {hideFace && <img className="Card-Back-Image" src={cardBack} />}
        </div>
      </div>
    </div>
  );
}

export default Card;
