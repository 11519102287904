import React, {useEffect, useRef, useState} from "react";

import './Quiz.css';

import useVri8er from "../../hooks/useVri8er";
import useGameService from "../../hooks/useGameService";
import {useRoomService} from "../../RoomServiceProvider";
import {useSocket} from "../../SocketProvider";
import Video from "../Video";


export default ({dispatch, game}) => {
  const {playerId} = useSocket();
  const {roomMembers, remoteStreams, myStream} = useRoomService();
  const { quizMasterId } = game;

  const otherPlayers = roomMembers.filter(m => m.id != playerId);
  const me = roomMembers.find(m => m.id == playerId);

  console.log('Me', me, playerId);

  let myPoints = game.points[playerId] || 0;
  return (
    <div className="Quiz">
      <div className={`Quiz-Other-Players-Container Num-Players-${otherPlayers.length}`}>
        <ul>
          {otherPlayers.map((player) => {
            let points = game.points[player.id] || 0;
            let streamInfo = remoteStreams[player.id];
            let stream = streamInfo.stream;
            let remoteMuted = streamInfo.muted;

            return <li key={player.id}>
              <div className="Quiz-Video-Top-Container">
                <div className="Quiz-Name-Container">{player.name}</div>
                <div className="Quiz-Points-Container"><Points points={points}/> poeng</div>
              </div>
              <div className="Quiz-Video-Container"><Video isMe={false} stream={stream} remoteMuted={remoteMuted} /></div>
              <div className="Quiz-Current-Task">
                <QuizCurrentTask player={player} game={game} dispatch={dispatch} playerId={playerId}/>
              </div>
            </li>
          })}
        </ul>
      </div>
      <div className="Quiz-Me-Row">
        <div className="Quiz-Me-Container">
          <Video isMe={true} stream={myStream}/>
          <div className="Quiz-My-Status">
            <div className="Quiz-Video-Top-Container">
              <div className="Quiz-Name-Container">{me.name}</div>
              <div className="Quiz-Points-Container"><Points points={myPoints}/> poeng</div>
            </div>
            <QuizMyStatus game={game} player={me} dispatch={dispatch} />
          </div>
        </div>
      </div>
    </div>
  )
}

const QuizCurrentTask = ({player, game, dispatch, playerId}) => {
  const reviewAnswer = (value) => {
    dispatch({
      type: 'REVIEW_ANSWER',
      data: {
        answerIsCorrect: value,
        player: {
          id: player.id
        }
      }
    })
  }

  if (game.quizMasterId == player.id) {
    return <span>QuizMaster!</span>;
  }
  else if (game.status == 'answering' && !game.answers[player.id] && game.quizMasterId != player.id) {
    return <span className="material-icons">schedule</span>
  }
  else if (game.status == 'answering' && game.answers[player.id] && game.quizMasterId != player.id) {
    return <span className="material-icons">question_answer</span>
  }
  else if (game.status == 'review' && typeof(game.answerCorrect[player.id]) != "undefined") {
    return <div className={`Quiz-Review-Answer-Container ${game.answerCorrect[player.id] ? 'Quiz-Review-Correct' : 'Quiz-Review-Wrong'}`}>
      {game.answers[player.id]}
    </div>;
  }
  else if (game.status == 'review' && game.quizMasterId == playerId) {
    return <div className="Quiz-Review-Answer-Review-Container">
      <button className="Quiz-Answer-Wrong"
              onClick={() => reviewAnswer(false)}
      ><span className="material-icons">close</span></button>
      {game.answers[player.id]}
      <button className="Quiz-Answer-Correct"
              onClick={() => reviewAnswer(true)}><span className="material-icons">done</span></button>
    </div>;
  }
  else if (game.status == 'review') {
    return <div className="Quiz-Review-Answer-Container">
      {game.answers[player.id]}
    </div>;
  }
  return null;
}

const QuizMyStatus = ({game, player, dispatch}) => {

  const sendAnswer = (e) => {
    dispatch({
      type: 'ANSWER',
      data: {
        answer: e.target.elements[0].value,
        player: {
          id: player.id
        }
      }
    })
    e.preventDefault();
  }

  if (game.quizMasterId == player.id) {
    // We are the quiz master!
    return <div className="Quiz-Question-Container">
      <h2>{game.question.category}</h2>
      <p className="Quiz-Question-Question">{game.question.question}</p>
      <p className="Quiz-Question-Answer">Svar: {game.question.answer}</p>
    </div>
  }
  else if (game.status == 'answering' && !game.answers[player.id]) {
    return <div className="Quiz-Question-Answer-Container">
      <form onSubmit={sendAnswer}>
        <AnswerInput />

      </form>
    </div>
  }
  else if (game.status == 'answering' || game.status == 'review') {
    let className = null;
    if (typeof(game.answerCorrect[player.id]) !== "undefined") {
      className =  game.answerCorrect[player.id] ? 'Quiz-Review-Correct' : 'Quiz-Review-Wrong';
    }
    return <div className="Quiz-Question-Answer-Container">
      <p>
        <span className={className}>{game.answers[player.id]}</span>
      </p>
    </div>
  }
  else {
    return null;
  }
}

const AnswerInput = () => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [])

  return <input ref={ref} type='text' name="answer" placeholder="Ditt svar" />
}

const Points = ({points}) => {
  const [className, setClassName] = useState('');

  useEffect(() => {
    setClassName('Quiz-Points');

    setTimeout( () => {
      setClassName('')
    }, 3000);

  }, [points]);

  return <span className={className}>{points}</span>
}