import {useEffect, useState} from "react";
var Peer = require('simple-peer')

const useBrowserCheck = () => {
  return Peer.WEBRTC_SUPPORT;
  /*
  const [browserSupported, setBrowserSupported] = useState('checking')
  useEffect( () => {

    const check = async  () => {
      let supported = true;
      if (!window.MediaStreamTrack || !navigator.mediaDevices ||
        !navigator.mediaDevices.enumerateDevices || !window.RTCPeerConnection) {
        supported = false;
      } else {
        // Check if we support WebRTC rollback:
        const pc = new RTCPeerConnection();
        try {
          await pc.setLocalDescription();
          await pc.setLocalDescription({type: "rollback"});
        } catch (err) {
          supported = false;
        }
      }

      if (supported) {
        setBrowserSupported('true');
      } else {
        setBrowserSupported('not-supported');
      }
    }
    check ();
  },[])
  return browserSupported;
  */
}

export default useBrowserCheck;