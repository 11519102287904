import React from 'react';
import './GameArea.css';
import DeckOfCards from "./DeckOfCards";
import {useRoomService} from "../RoomServiceProvider";

export default (props) => {
  const { roomMembers} = useRoomService();
  const { currentDeck, playedCards, status, winnerId} = props.game;
  const { dispatch, playerId } = props;

  const onPickCard = () => {
    dispatch({
      type: 'PICK_CARD',
      data: {
        player: {
          id: playerId
        }
      }
    })
  }

  const restartGame = () => {
    if (status != 'complete') {
      if (!window.confirm("Er du sikker på at du vil starte på nytt midt i spelet? Juks?!")) {
        return;
      }
    }
    dispatch({
      type: 'RESTART_GAME',
      data: {
        player: {
          id: playerId
        }
      }
    })
  }

  let winner = "";
  if (winnerId >= 0) {
    winner = roomMembers.find(p => p.id == winnerId).name;
  }

  return <div className="GameArea">
    {status == 'playing' && <div className="GameArea-Deck-Container">
      <DeckOfCards cards={currentDeck} hideFace={true} reverse={false} onClick={onPickCard}/>
      <DeckOfCards cards={playedCards} hideFace={false} reverse={true}/>
    </div>}
    <div className="GameArea-Actions-Container">
      <button onClick={restartGame}>Start på nytt</button>
    </div>
    {status == 'complete' && <div className="GameArea-Winner">{winner} vant!</div>}
  </div>
}