import React, {useEffect, useRef, useState} from "react";
import './Vri8er.css';
import Player from "./Player";
import GameArea from "./GameArea";
import useVri8er from "../hooks/useVri8er";
import useGameService from "../hooks/useGameService";
import {useRoomService} from "../RoomServiceProvider";
import {useSocket} from "../SocketProvider";
import {hot} from "react-hot-loader/root";


export default hot(({dispatch, game}) => {
  const {playerId} = useSocket();
  const {roomMembers} = useRoomService();


  const myRoomIdx = roomMembers.findIndex(p => p.id == playerId);

  let playersAfterMe = roomMembers.slice(myRoomIdx+1)
    .concat(roomMembers.slice(0, myRoomIdx))
  const me = roomMembers.find(m => m.id == playerId);

  let player2 = null;
  let player3 = null;
  let player4 = null;

  if (playersAfterMe.length == 1) {
    player3 = playersAfterMe[0]
  }
  else if (playersAfterMe.length == 2) {
      player2 = playersAfterMe[0]
      player3 = playersAfterMe[1]
  }
  else if (playersAfterMe.length == 3) {
    player2 = playersAfterMe[0]
    player3 = playersAfterMe[1]
    player4 = playersAfterMe[2]
  }

  return (
    <div className="Vri8er">
      <div className="Vri8er-Top-Player">
        {player3 && <Player game={game} playerId={playerId} player={player3} dispatch={dispatch}/>}
      </div>
      <div className="Vri8er-Middle-Row">
        {player2 && <Player direction="vertical" game={game} playerId={playerId} player={player2} dispatch={dispatch}/>}
        <GameArea game={game} playerId={playerId} dispatch={dispatch}/>
        {player4 && <Player direction="vertical" game={game} playerId={playerId} player={player4} dispatch={dispatch}/>}
      </div>
      <Player game={game} playerId={playerId} player={me} dispatch={dispatch}/>
    </div>
  )

})