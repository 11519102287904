import MyCards from "./MyCards";
import './Player.css';
import React, {useEffect, useRef, useState} from "react";
import ChooseSuit from "./ChooseSuit";
import {useRoomService} from "../RoomServiceProvider";
import Video from "./Video";

export default (props) => {
  const {remoteStreams, myStream} = useRoomService();
  const {currentTurnId, players } = props.game;
  const { player, playerId, dispatch, direction } = props;
  const [chooseSuit, setChooseSuit] = useState(false)
  const videoRef = useRef();
  const currentCard = useRef ();

  const doPlayCard = (card) => {
    dispatch({
      type: 'PLAY_CARD',
      data: {
        player: {
          id: playerId
        },
        card: card
      }
    })
  }

  const sendPass = () => {
    dispatch({
      type: 'PASS',
      data: {
        player: {
          id: playerId
        },
      }
    })
  }

  const onChooseSuit = (e, suit) => {

    const card = currentCard.current;
    card.newSuit = suit;
    doPlayCard(card);
    setChooseSuit(false)
    e.stopPropagation();
  }

  const playCard = (card) => {
    if (player.id != currentTurnId) {
      alert('Det er ikkje din tur!');
      return
    }
    if (card.rank == 8) {
      setChooseSuit(true);
      currentCard.current = card;
    }
    else {
      doPlayCard(card)
    }
  }

  console.log('Player hide face', player.id, playerId)
  let videoStream = null;
  let remoteMuted = false;

  if (player.id != playerId && remoteStreams[player.id]) {
    videoStream = remoteStreams[player.id].stream;
    remoteMuted = remoteStreams[player.id].muted;
  }
  else if (player.id == playerId && myStream) {
    videoStream = myStream;
  }

  console.log('Remote muted', remoteMuted);

  if (player.id == playerId) {
    return <div className={`Player`}>
      <div className="Player-Bottom-Container">
        <div className="Player-Video-Container">
          <Video stream={videoStream} isMe={true} remoteMuted={remoteMuted} />
          <div className="Player-Name-Container">
            <div className={`Player-Name ${player.id == currentTurnId ? 'Player-Current' : ''}`}>
              {player.id == currentTurnId && 'Din tur'} {player.name}</div>
          </div>
        </div>
        <div className="Player-Pass-Container">
          <button disabled={currentTurnId != player.id} onClick={sendPass}>Pass</button>
        </div>
        <div className="Player-Cards-Container">
          <MyCards cards={players[player.id] ? players[player.id].cards : []}
                   hideFace={player.id != playerId} playCard={playCard}/>
          {chooseSuit && <ChooseSuit chooseSuit={onChooseSuit} onClose={() => setChooseSuit(false)}/>}
        </div>
      </div>
    </div>
  }

  return <div className={`Player Player-Is-Other`}>
    <div className="Player-Bottom-Container">
      <div className="Player-Video-Container">
        <Video stream={videoStream} isMe={false}  remoteMuted={remoteMuted} />
        <div className="Player-Name-Container">
          <div className={`Player-Name ${player.id == currentTurnId ? 'Player-Current' : ''}`}>
            {player.id == currentTurnId && 'Din tur'} {player.name}</div>
        </div>
        <div className="Player-Cards-Container">
          <MyCards cards={players[player.id] ? players[player.id].cards : []}
                   hideFace={true} playCard={playCard}/>
        </div>
      </div>
    </div>
  </div>
}
