import React from 'react';
import logo from './logo.svg';
import './App.css';
import { SocketProvider} from "./SocketProvider";
import * as Sentry from '@sentry/browser';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import StartPage from "./Components/StartPage";
import GamePage from "./Components/GamePage";
import {RoomServiceProvider} from "./RoomServiceProvider";
import {MediaServiceProvider} from "./MediaProvider";


if ( process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://da22f244d99a4de79cc48a7e91621359@o378438.ingest.sentry.io/5201825'
  });
}

function App() {

  return (
    <SocketProvider>
      <MediaServiceProvider>
      <RoomServiceProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              <StartPage />
            </Route>
            <Route exact path="/:id" component={GamePage} />
          </Switch>
        </Router>
      </RoomServiceProvider>
      </MediaServiceProvider>
    </SocketProvider>
  );
}

export default App;
