import {useEffect, useState} from "react";

function shuffleDeck(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const getDeck = () => {
  const suits = ['C', 'D', 'H', 'S'];
  const ranks = [2, 3, 4, 5, 6, 7, 8, 9, 10, 'J', 'Q', 'K', 'A'];

  const cards = [];
  for (const suit of suits) {
    for (const rank of ranks) {
      cards.push({
        suit: suit,
        rank: rank
      })
    }
  }
  return cards;
}

const useCardDeck = (shuffle) => {
  const [deck, setDeck] = useState([])

  useEffect(() => {
    let cards = getDeck();
    if (shuffle) {
      cards = shuffleDeck(cards);
      setDeck(cards);
    }

  }, [])

  return deck;
}

export default useCardDeck;