import React, {useEffect, useRef, useState} from "react";
import './PreGame.css';
import {useHistory, useRouteMatch} from "react-router";
import {useRoomService} from "../RoomServiceProvider";
import {useSocket} from "../SocketProvider";
import Video from "./Video";
import {hot} from "react-hot-loader/root";
export default hot(() => {
  const videoRef = useRef();
  const history = useHistory();
  const match = useRouteMatch()
  const { playerId } = useSocket();
  const { roomMembers, roomId, remoteStreams, newGame, myStream } = useRoomService();
  const [startSpillPopup, setStartSpillPopup] = useState(false)

  console.log('Match', match)
  const startVri8er = () => {
    newGame('vri8er');
  }

  const startQuiz = () => {
    newGame('quiz');
  }

  const startAmerikaner = () => {
    newGame('amerikaner');
  }

  console.log('Pregame render, remote streams', remoteStreams);

  return (
    <div className="PreGame-Outer-Container">
      <div>
      <h2>Vent her til alle har komt.. Spelkode: {roomId}</h2>
      <ul className="PreGame-PlayerList">
        {roomMembers.map((player) => {
          let streamInfo = remoteStreams[player.id] && remoteStreams[player.id];
          let remoteMuted = false;
          let stream = null;
          if (streamInfo) {
            remoteMuted = streamInfo.muted;
            stream = streamInfo.stream;
          }
          let isMe = false;
          if (player.id == playerId) {
            console.log('Player is me', myStream)
            isMe = true;
            stream = myStream;
          }

          console.log('Stream ', remoteStreams, remoteStreams[player.id], stream, isMe)

          return <li key={player.id}>
            <div className="PreGame-Stream-Container">
              <Video stream={stream} isMe={isMe} remoteMuted={remoteMuted}/>
              <div className="PreGame-Name-Container">{player.name}</div>
            </div>
          </li>
        })}
      </ul>


      <div className="PreGame-StartSpill">
        <button onClick={() => setStartSpillPopup(true)}>
          Start nytt spel!
        </button>
      </div>
        {startSpillPopup && <div className="PreGame-Popup-Container"
                                 onClick={() => setStartSpillPopup(false)}>
          <div className="PreGame-Popup-Content">
            <button onClick={startVri8er}>Start vri 8er</button>
            <button onClick={startQuiz}>Start quiz</button>
          </div>
        </div>
        }
      {/*      <div className="PreGame-StartSpill">
        <button onClick={startAmerikaner}>Start quiz!</button>
      </div>
      */}
      </div>
    </div>

  )
});