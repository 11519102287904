import {useEffect, useState} from "react";

export default (cards) => {
  const [sortedCards, setSortedCards] = useState([]);

  useEffect(() => {
    const suits = ['H', 'S', 'D', 'C']
    const ranks = [2, 3, 4, 5, 6, 7, 8, 9, 10, 'J', 'Q', 'K', 'A'];

    const sc = [];
    for (const suit of suits) {
      const cardsOfSuit = cards.filter(card => card.suit == suit);

      for (const rank of ranks) {
        for (const card of cardsOfSuit) {
          if (card.rank == rank) {
            sc.push(card)
          }
        }
      }
    }
    console.log('Sorted cards', cards);
    setSortedCards(sc);
  }, [cards]);

  return sortedCards;
}
